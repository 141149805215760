import "core-js/modules/es.array.slice.js";
import CommonFunction from "@/utils/commonFunction";
import eventBus from "@/utils/eventBus";
import Task from "@/utils/task";
import UI from "@/utils/ui";
export default {
  data: function data() {
    return {
      CommonFunction: CommonFunction,
      taskCount: 0,
      showTask: false,
      task: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.loadTask();

    if (!window.taskTimer) {
      window.taskTimer = setInterval(function () {
        _this.loadTask();
      }, 10000);
    }
  },
  methods: {
    loadTask: function loadTask() {
      var web3 = window.web3;
      var key = 'TASK_QUEUE_' + window.connectedAddress;
      var task = localStorage.getItem(key);
      task = task ? JSON.parse(task) : [];
      var _taskCount = 0;

      var _loop = function _loop(i) {
        var item = task[i];
        task[i].title = item.title.slice(0, 1).toUpperCase() + item.title.slice(1);

        if (!item.status) {
          _taskCount++;
        } // getTransaction


        if (web3.trx) {
          web3.trx.getTransaction(item.hash).then(function (res) {
            if (res.ret && res.ret[0].contractRet == 'SUCCESS') {
              UI.toast('Transaction successful', 'HASH: ' + CommonFunction.formatString(item.hash, 8, 8), 5000);
              Task.remove(item.hash);
              eventBus.$emit('on' + item.action, item.hash);
            }
          });
        }
      };

      for (var i in task) {
        _loop(i);
      }

      this.taskCount = _taskCount;
      this.task = task;
    },
    deleteTask: function deleteTask(item) {
      Task.remove(item.hash);
      this.loadTask();
    }
  }
};