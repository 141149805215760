import UI from '@/utils/ui';
import CommonFunction from "@/utils/commonFunction";
export default {
  name: 'AppFooter',
  data: function data() {
    return {
      CommonFunction: CommonFunction,
      active: null
    };
  },
  mounted: function mounted() {
    this.changeTab();
  },
  methods: {
    changeTab: function changeTab() {
      for (var i in this.tabs) {
        if (this.tabs[i].to == this.$route.path) {
          this.active = parseInt(i);
          break;
        }
      }
    },
    onChange: function onChange(idx) {
      // if (this.tabs[idx].to.indexOf('https://') > -1) {
      //     window.location.href = this.tabs[idx].to
      //     return
      // }
      if (this.$route.path != this.tabs[idx].to) {
        this.active = idx;
        this.$router.push(this.tabs[idx].to);
      }
    }
  },
  computed: {
    tabs: function tabs() {
      return [{
        name: t('Home'),
        to: '/index',
        active: require('../../assets/images/tabs/tab1-active.png'),
        inactive: require('../../assets/images/tabs/tab1.png')
      }, {
        name: t('Exchange'),
        to: '/exchange',
        active: require('../../assets/images/tabs/tab2-active.png'),
        inactive: require('../../assets/images/tabs/tab2.png')
      }, {
        name: t('Serve'),
        to: '/server',
        active: require('../../assets/images/tabs/tab3-active.png'),
        inactive: require('../../assets/images/tabs/tab3.png')
      }, {
        name: t('Mine'),
        to: '/mine',
        active: require('../../assets/images/tabs/tab4-active.png'),
        inactive: require('../../assets/images/tabs/tab4.png')
      }];
    }
  }
};