import _createForOfIteratorHelper from "E:/work/coding/ruoyi-vue-pro-master/defi/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _slicedToArray from "E:/work/coding/ruoyi-vue-pro-master/defi/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.math.trunc.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import axios from "axios";
import eventBus from '@/utils/eventBus';
import HeaderBlock from './Header';
import FooterBlock from './Footer';
import UI from "@/utils/ui";
import NetworkConfig from '@/config/network';
export default {
  components: {
    HeaderBlock: HeaderBlock,
    FooterBlock: FooterBlock
  },
  data: function data() {
    return {
      UI: UI,
      inited: true,
      viewMode: localStorage.getItem('VIEWMODE') ? localStorage.getItem('VIEWMODE') : 'light',
      show: false,
      show2: false,
      netWorkList: Object.values(NetworkConfig),
      tc: null
    };
  },
  created: function created() {
    var _this = this;

    eventBus.$on('afterInit', function () {
      _this.inited = true;
    });
    eventBus.$on('ChangeViewMode', function (mode) {
      _this.viewMode = mode;
    });
    eventBus.$on('registerd', function () {
      _this.tc = _this.userInfo.notices.find(function (r) {
        return r.isDialog && r.readTime === null;
      });

      if (_this.tc) {
        _this.show2 = true;
        return;
      }

      var show = Object.keys(_this.defaultActivity).length > 0;
      _this.show = show;
    });
  },
  methods: {
    enterOk: function enterOk() {
      var _this2 = this;

      var url = "/defi/site/readNotice";
      var params = {
        address: this.currentAccount,
        chain: this.defaultNetworkConfig.chainName
      };
      axios.post(url, params).then(function (res) {
        _this2.show2 = false;

        _this2.register();
      });
    },
    accept: function accept() {
      var _this3 = this;

      var url = "/defi/site/activity";
      var params = {
        id: this.defaultActivity.id
      };
      axios.post(url, params).then(function (res) {
        _this3.show = false;

        _this3.register();
      });
    },
    register: function register() {
      var _this4 = this;

      var url = "/defi/site/register";
      var params = {
        address: this.currentAccount,
        chain: this.defaultNetworkConfig.chainName
      };
      axios.post(url, params).then(function (res) {
        _this4.setUserInfo(res.data.data);
      }).catch(function (e) {});
    },
    getDiffDay: function getDiffDay(endTime, startTime) {
      var totalDays = Math.floor((endTime - startTime) / (1000 * 3600 * 24));
      return totalDays + 1;
    },
    getTimeYYMMDD: function getTimeYYMMDD(date) {
      date = new Date(date);
      var year = date.getFullYear().toString().padStart(4, '0');
      var month = (date.getMonth() + 1).toString().padStart(2, '0');
      var day = date.getDate().toString().padStart(2, '0');
      var hours = date.getHours().toString().padStart(2, '0');
      var minutes = date.getMinutes().toString().padStart(2, '0');
      var seconds = date.getSeconds().toString().padStart(2, '0'); //const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

      var formattedDate = "".concat(year, "/").concat(month, "/").concat(day);
      return formattedDate;
    },
    formater: function formater(data) {
      if (!data) return '0.00';
      data = Number(data);
      var intPart = Math.trunc(data);
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      var newArr = data.toString().split('.');
      var ret = intPartFormat;
      return ret;
    },
    formater222: function formater222(data) {
      if (!data) return '0.00';
      data = Number(data);
      var intPart = Math.trunc(data);
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');

      var _data$toString$split = data.toString().split('.'),
          _data$toString$split2 = _slicedToArray(_data$toString$split, 2),
          zsArr = _data$toString$split2[0],
          xsArr = _data$toString$split2[1];

      var ret = intPartFormat + "." + xsArr;
      return ret;
    }
  },
  computed: {
    userInfo: {
      get: function get() {
        return this.$store.state.userInfo;
      }
    },
    defaultActivity: {
      get: function get() {
        if (this.userInfo.activitys.length > 0) {
          var first = this.userInfo.activitys.find(function (r) {
            return !r.stakeTime;
          });

          if (first) {
            return first;
          }
        }

        return {};
      }
    },
    currentAccount: function currentAccount() {
      var currentAccount = this.$store.state.currentAccount;
      return currentAccount;
    },
    defaultNetworkConfig: {
      get: function get() {
        return this.netWorkList.find(function (r) {
          return r.default;
        });
      },
      set: function set(item) {
        var _iterator = _createForOfIteratorHelper(this.netWorkList),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var _item = _step.value;
            _item.default = false;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        item.default = true;
      }
    }
  }
};