import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConfig from "../config/wallet";
import { getCurrentInstance } from "vue";
import store from '../store'
import Contract from './contract'
import CommonFunc from "@/utils/commonFunction";
import eventBus from '@/utils/eventBus'

function isMobile() {
    return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
}

async function connect(flag){
    const networkConfig = await CommonFunc.getCurrentNetwork()
    const providerOptions = WalletConfig.providerOptions;

    let providerItems={};
    for(let i in providerOptions){
        let item = providerOptions[i];
        if(!item.options){
            continue
        }
        if(item.options.hasOwnProperty("supports")){
            if(!item.options.supports.includes(networkConfig.network)){
                continue;
            }
        }
        item.options.chainId=networkConfig.chainId;
        if(networkConfig.rpcs){
            item.options.rpc = networkConfig.rpcs
        } else {
            const _rpcs = {}
            _rpcs[networkConfig.chainId] = networkConfig.rpcUrl
            item.options.rpc = _rpcs
        }
        providerItems[i]=item;

    }

    let provider, web3Modal
 
    try {
        web3Modal = new Web3Modal({
            network: networkConfig.network,
            cacheProvider: true,
            theme: {
                background: "#2d2672",
                main: "rgb(199, 199, 199)",
                secondary: "rgb(136, 136, 136)",
                border: "rgba(195, 195, 195, 0.54)",
                hover: "rgb(54, 55, 98)"
              },
            disableInjectedProvider:networkConfig.chainName==="TRC"?true: false, 
            providerOptions:providerItems,
        })
        if(web3Modal && !flag){
            setTimeout(() => {
                if(!provider){
                    console.log('ClearCachedProvider and reconnect')
                    web3Modal.clearCachedProvider()
                    connect(true)
                }
            }, 3000)
        }
        
        provider = await web3Modal.connect()
    } catch (err){
        console.log(err,"err")
        web3Modal.clearCachedProvider()
    }

    if(provider){
        window.provider = provider
        window.web3 = provider.type == 'TRON' ? provider.web3 : new Web3(provider);
        let connectedAddress;
        if(window.web3.trx){
            connectedAddress = window.web3.defaultAddress.base58
        } else {
            const chainId = await window.web3.eth.getChainId()
            if(chainId != networkConfig.chainId){
                await _addChain(provider, networkConfig)
            }
        
            let accounts=await window.web3.eth.getAccounts();
            connectedAddress=accounts[0];
        }
        window.connectedAddress = connectedAddress
        window.web3Modal = web3Modal

        store.dispatch('setCurrentAccount', window.connectedAddress)
        
        //getCurrentInstance()?.proxy.$eventBus.$emit('WALLET_CONNECTED', window.connectedAddress)
        eventBus.$emit("WALLET_CONNECTED",window.connectedAddress);
        subscribeProvider(window.provider)

        // Init Contract
        window.connectedAddress && Contract.init(networkConfig.chainName)
    }
}

async function _addChain(provider, networkConfig){
    let res = await provider.request({
        method: 'eth_requestAccounts'
    })
    if(!res || res.length == 0){
        return false
    }

    try {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{
            chainId: web3.utils.numberToHex(networkConfig.chainId)
          }]
        })
      } catch (e) {
        if (e.code === 4001) {
          return;
        }
        try {
          await this.web3.currentProvider.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: web3.utils.numberToHex(networkConfig.chainId),
                chainName: networkConfig.chainName,
                nativeCurrency: {
                    name: networkConfig.chainSymbol,
                    symbol: networkConfig.chainSymbol,
                    decimals: networkConfig.decimals
                },
                rpcUrls: networkConfig.rpcUrl.split(','),
                blockExplorerUrls: [networkConfig.scanUrl]
            }]
          })
        } catch (ee) {

        }
      }
}

async function disconnect() {
    if (window.provider == null) {
        return
    }
    if(typeof(window.provider.disconnect) == 'function'){
        await window.provider.disconnect()
    }
    await window.web3Modal.clearCachedProvider()
    window.provider = null
    window.connectedAddress = null
    window.web3Modal = null

    store.dispatch('setCurrentAccount', window.connectedAddress)
    //getCurrentInstance()?.proxy.$eventBus.emit('WALLET_DISCONNECTED')
    eventBus.$emit("WALLET_DISCONNECTED",window.connectedAddress);
}

function subscribeProvider(provider){
    if (!provider.on) {
        return
    }
    provider.on("close", () => {
        disconnect()
    })
    provider.on("chainChanged", () => {
        console.log('chainChanged')
    })
    provider.on("networkChanged", () => {
        console.log('networkChanged')
    })
    provider.on("accountsChanged", () => {
        console.log('accountsChanged')
        window.location.reload()
    })
}

export default {
    connect,
    disconnect
}
